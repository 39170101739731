import React, { Component } from 'react';

const initData = {
    heading: "Frequently asked questions",
    headingTwo: "Have questions? Look here",
    headingText: "Hier findest du einige oft gefragte Fragen aus der Community über die Funktionen der App, wie auch über die Zahlungsabwicklung des Pro Abonnement.",
    headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
}

const faqData = [
    {
        id: 1,
        title: "Wie installiert man die App?",
        content: "Die App kann je nach Gerätehersteller aus den unterschiedlichen Stores heruntergeladen werden. Im Falle eines Apple-Produkts kann sie aus dem AppStore und im Falle eines Android-Produkts aus dem Google-PlayStore heruntergeladen werden."
      },
      {
        id: 2,
        title: "Was kann AiTutor?",
        content: "AiTutor ermöglicht es dem Nutzer Text- oder Rechenaufgaben entweder mit einer Kamera einzulesen oder diese direkt in einem Chat Dialogfenster zu stellen. Dort kann individuell auf die Lösungen eingegangen werden. Zudem gibt es eine große Datenbank an Lernzetteln zu verschiedenen Themen."
      },
      {
        id: 3,
        title: "Was ist das Pro Abonnement",
        content: "Das Pro Anonnement bietet dem Nutzer eine bessere Erfahrung der App. Zum einen muss der Nutzer keine Werbung mehr anschauen und außerdem wird eine größere Brandbeite an Funktionen geboten. Ein Beispiel dafür ist der Zugriff der App auf Live Daten."
      }
]

const faqDataTwo = [
    {
        id: 1,
        title: "Wie kann man das Abonnement bezahlen?",
        content: "Das Abonnement wird über die offiziellen Zahlungsabwickler der verschiedenen Stores abgrechnet. Im Falle eines Apple-Produkts wird die Zahlung direkt über die Abonnement Funktion des AppStores gehandhabt und bietet so einen sicheren Zahlungsverkehr."
      },
      {
        id: 2,
        title: "Gibt es die App auch für IPad?",
        content: "Auch für IPad haben wir die App optimiert, so dass sie problemlos über die gesamte Bildschirmbreite des IPads genutzt werden kann. Dies gilt sowohl für das Hoch- wie auch für das Querformat."
      },
      {
        id: 3,
        title: "Kann man das Abonnement monatlich kündigen?",
        content: "Das Pro Abonnement kann ganz einfach über den Zahlungsanbieter des jeweiligen Stores gekündigt werden. Das Abonnement kann im Fall eines Apple-Produkts direkt über die AppStore Einstellungen gekündigt werden und für alle folgenen Monate fallen keine weiteren Kosten an."
      }
]

class FaqSection extends Component {
    state = {
        initData: {},
        faqData: [],
        faqDataTwo: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            faqData: faqData,
            faqDataTwo: faqDataTwo
        })
    }
    render() {
        return (
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.faqData.map((item, idx) => {
                                            return(
                                                <div key={`fo_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.faqDataTwo.map((item, idx) => {
                                            return(
                                                <div key={`ft_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FaqSection;