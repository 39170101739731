import React, { Component } from 'react';

const initData = {
    heading: "Was AiTutor von anderen unterscheidet",
    buttonText: "Download",
    thumbOne: "/img/features_thumb.png"
}

const data = [
    {
        id: 1,
        iconClass: "fab fa-buffer",
        text: "Wir arbeiten bei Ausfällen und bei Fehlern mit Hochdruck an Behebung und der Verbesserung der App."
      },
      {
        id: 2,
        iconClass: "fas fa-brush",
        text: "Durch den Chat, kannst du deine Aufgabenstellungen genau anpassen um das beste Ergebnis zu erhalten."
      },
      {
        id: 3,
        iconClass: "fas fa-burn",
        text: "Die App ermöglicht dir deine Aufgaben mit Live Daten aus dem Internet zu lösen."
      },
      {
        id: 4,
        iconClass: "fas fa-cart-arrow-down",
        text: "Unser Premium Abonnement ermöglicht dir viele weitere Funktionen, welche stets erweitert werden."
      }
]

class ServiceSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section service-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">
                        <h2 className="text-capitalize mb-4">{this.state.initData.heading}</h2>
                        {/* Service List */}
                        <ul className="service-list">
                            {this.state.data.map((item, idx) => {
                                return(
                                    <div key={`so_${idx}`}>
                                        {/* Single Service */}
                                        <li className="single-service media py-2">
                                            <div className="service-icon pr-4">
                                                <span><i className={item.iconClass} /></span>
                                            </div>
                                            <div className="service-text media-body">
                                                <p>{item.text}</p>
                                            </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <a href="#download" className="btn btn-bordered mt-4 scroll">{this.state.initData.buttonText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
                        {/* Service Thumb */}
                        <div className="service-thumb mx-auto">
                        <img src={this.state.initData.thumbOne} alt="" />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSection;