import React, { Component } from 'react';

const initData = {
    preHeading: "Einigartige",
    preHeadingspan: "Funktionen",
    heading: "Was AiTutor einzigartig macht",
    headingText: "Bei AiTutor steht der Nutzer an erster Stelle. Wir arbeiten stets an der Weiterentwicklung der Performence sowie Funktionen, als auch an der Sicherheitsoptimierung unserer Plattform.",
    headingTexttwo: ""
}

const data = [
    {
        image: "/img/featured_image_1.png",
        title: "Funktionell",
        content: "Unsere App probieren wir auf Funktionalität und Nutzen für die beste Anwendung aufzubauen"
    },
    {
        image: "/img/featured_image_2.png",
        title: "Live Chat",
        content: "Der Live Chat ermöglicht eine interaktive Oberfläche zur Aufgaben- und Problemlösung"
    },
    {
        image: "/img/featured_image_3.png",
        title: "Sichere Daten",
        content: "Bei AiTutor speichern wir nur erforderliche Nutzerdaten, welche wir vertrauensvoll behandeln"
    },
]

class FeatureSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                                    <i className="far fa-lightbulb text-primary mr-1" />
                                    <span className="text-primary">{this.state.initData.preHeading}</span> {this.state.initData.preHeadingspan}
                                </span>
                                <h2>{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`fo_${idx}`} className="col-12 col-md-6 col-lg-4 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Featured Image */}
                                    <div className="featured-img mb-3">
                                        <img className="avatar-sm" src={item.image} alt="" />
                                    </div>
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;