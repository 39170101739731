import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import ComingSoon from "../components/InnerSection/ComingSoon";
import ErrorPage from "../components/ErrorPage/404";
import Imprint from "../components/ImprintPage/Imprint";
import Privacy from "../components/PrivacyPage/Privacy";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeOne} />
            <Route path="/coming-soon" component={ComingSoon} />
            <Route path="/error" component={ErrorPage} />
            <Route path="/imprint" component={Imprint} />
            <Route path="/privacy-policy" component={Privacy} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;