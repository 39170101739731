import React, { Component } from 'react';

const initData = {
    heading: "Wie funktioniert AiTutor?",
    headingText: "Hier erklären wir dir Schritt für Schritt wie du AiTutor zum lernen und verbessern deiner Aufgaben verwenden kannst.",
}

const data = [
    {
        id: 1,
        image: "/img/work_thumb_1.png",
        title: "Installiere die App",
        content: "AiTutor ist für alle IPhones, IPads im AppStore sowie für alle Androidgeräte im PlayStore verfügbar."
      },
      {
        id: 2,
        image: "/img/work_thumb_2.png",
        title: "Stelle eine Aufgabe",
        content: "Du kannst entweder über die Kamera-Funktion oder direkt über den Chat deine Aufgabe stellen und die Lösung über den Chat optimieren."
      },
      {
        id: 3,
        image: "/img/work_thumb_3.png",
        title: "Optimiere dein Lernen",
        content: "Die Lösung kannst du verwenden um beispielsweise deine Hausaufgaben zu kontrollieren und diese zu verbessern."
      }
]

class Work extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            {/* Work Content */}
                            <div className="work-content text-center">
                                <h2 className="text-white">{this.state.initData.heading}</h2>
                                <p className="text-white my-3 mt-sm-4 mb-sm-5">{this.state.initData.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`w_${idx}`} className="col-12 col-md-4">
                                {/* Single Work */}
                                <div className="single-work text-center p-3">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img className="avatar-md" src={item.image} alt="" />
                                    </div>
                                    <h3 className="text-white py-3">{item.title}</h3>
                                    <p className="text-white">{item.content}</p>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;