import React, { Component } from 'react';

const initData = {
    heading: "Unser Team",
    headingText: "Wie sind zwei junge Informatikstudenten aus Baden-Württemberg und Niedersachsen und arbeiten seit Anfang 2023 an der App.",
}

const teamData = [
    {
        id: 1,
        image: "/img/julian.png",
        title: "Julian Ambrozy",
        teamPost: "Founder & App-Entwickler",
        twitter: "https://twitter.com/Gommzy1",
        linkedin: "https://www.linkedin.com/in/julianambrozy/"
      },
      {
        id: 2,
        image: "/img/hagen.png",
        title: "Hagen Kanne",
        teamPost: "Co-Founder & Web-Entwickler",
        twitter: "https://twitter.com/0xHagenK",
        linkedin: "https://de.linkedin.com/in/hagen-kanne-298847232"
      },
]

const teamIcons = [
    {
        id: 1,
        iconClass: "fab fa-linkedin-in"
      },
      {
        id: 2,
        iconClass: "fab fa-twitter"
      },
]

class Team extends Component {
    state = {
        initData: {},
        teamData: [],
        teamIcons: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            teamData: teamData,
            teamIcons: teamIcons
        })
    }
    render() {
        return (
            <section id="team" className="section team-area team-style-two bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        {this.state.teamData.map((item, idx) => {
                            return(
                                <div key={`t_${idx}`} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {/* Single Team */}
                                    <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    {/* Team Thumb */}
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={item.image} alt="" />
                                        {/* Team Overlay */}
                                        <div className="team-overlay radius-100">
                                        <h4 className="team-name text-white">{item.title}</h4>
                                        <h5 className="team-post text-white mt-2 mb-3">{item.teamPost}</h5>
                                        {/* Team Icons */}
                                        <div className="team-icons">
                                            {this.state.teamIcons.map((item1, idx) => {
                                                const link = item1.iconClass === "fab fa-twitter" ? item.twitter : item.linkedin;
                                                return(
                                                    <a
                                                        key={`ti_${idx}`}
                                                        className="p-2"
                                                        href={link}
                                                    >
                                                    <i className={item1.iconClass} />
                                                    </a>
                                                );
                                            })}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Team;