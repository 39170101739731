import React, { Component } from 'react';

const initData = {
    image: "/img/logo.png",
    text: "Eine App welche das automatisierte lösen von Aufgaben zum lernen und verbessern ermöglicht.",
    linkText_1: "Nützliche Links",
    linkText_2: "Unternehmen",
    linkText_3: "Download",
}

const iconList = [
    {
        id: 1,
        link: "instagram",
        iconClass: "fab fa-instagram"
      },
      {
        id: 2,
        link: "discord",
        iconClass: "fab fa-discord"
      },
      {
        id: 3,
        link: "mail",
        iconClass: "fas fa-envelope"
    }
]

const footerList_1 = [
    {
        id: 1,
        text: "Home"
      },
      {
        id: 2,
        text: "Features"
      },
      {
        id: 3,
        text: "Screenshots"
      },
      {
        id: 4,
        text: "Abonnement"
      },
      {
        id: 5,
        text: "Download"
    }
]

const footerList_2 = [
    {
        id: 1,
        text: "Impressum",
        link: "/imprint"
      },
      {
        id: 2,
        text: "Datenschutzerklärung",
        link: "/privacy-policy"
      },
      {
        id: 3,
        text: "Kontakt",
        link: "/error"
    },
    
]

const footerList_3 = [
    {
        id: 1,
        image: "/img/google-play-black.png",  
        link: "https://play.google.com/store/apps/details?id=de.gommzy.aitutor&hl=de&gl=US"
      },
      {
        id: 2,
        image: "/img/app-store-black.png",
        link: "https://apps.apple.com/de/app/aitutor-hausaufgaben-lösen/id1668208873"
    }
]

class FooterSection extends Component {
    state = {
        initData: {},
        iconList: [],
        footerList_1: [],
        footerList_2: [],
        footerList_3: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            iconList: iconList,
            footerList_1: footerList_1,
            footerList_2: footerList_2,
            footerList_3: footerList_3
        })
    }
    render() {
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" href="/#">
                                <img className="logo" src={this.state.initData.image} alt="" />
                                </a>
                                <p className="mt-2 mb-3">{this.state.initData.text}</p>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {this.state.iconList.map((item, idx) => {
                                        if(item.id === 1) {
                                            return(
                                                <a key={`fi_${idx}`} className={item.link} href="https://www.instagram.com/aitutorapp/">
                                                    <i className={item.iconClass} />
                                                    <i className={item.iconClass} />
                                                </a>
                                            );
                                        } else if(item.id === 2) {
                                            return(
                                                <a key={`fi_${idx}`} className={item.link} href="https://discord.gg/ZP7WShDjad">
                                                    <i className={item.iconClass} />
                                                    <i className={item.iconClass} />
                                                </a>
                                            );
                                        } else if(item.id === 3) {
                                            return(
                                                <a key={`fi_${idx}`} className={item.link} href="mailto:contact@aitutor.work">
                                                    <i className={item.iconClass} />
                                                    <i className={item.iconClass} />
                                                </a>
                                            );
                                        } else {
                                            return(
                                                <a key={`fi_${idx}`} className={item.link} href="/#">
                                                    <i className={item.iconClass} />
                                                    <i className={item.iconClass} />
                                                </a>
                                            );
                                        }

                                    })}
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.initData.linkText_1}</h3>
                                <ul>
                                    {this.state.footerList_1.map((item, idx) => {
                                        return(
                                            <li key={`flo_${idx}`} className="py-2"><a href="/#">{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.initData.linkText_2}</h3>
                                <ul>
                                    {this.state.footerList_2.map((item, idx) => {
                                        if(item.id === 3) {
                                            return(
                                                <li key={`flt_${idx}`} className="py-2"><a href="mailto:contact@aitutor.work">{item.text}</a></li>
                                            );
                                        } else {
                                            return(
                                                <li key={`flt_${idx}`} className="py-2"><a href={item.link}>{item.text}</a></li>
                                            );
                                        }
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.initData.linkText_3}</h3>
                                {/* Store Buttons */}
                                <div className="button-group store-buttons store-black d-flex flex-wrap">
                                    {this.state.footerList_3.map((item, idx) => {
                                        return(
                                            <a key={`flth_${idx}`} href={item.link}>
                                                <img src={item.image} alt="" />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyright 2023 AiTutor UG (haftungsbeschränkt) Alle rechte vorbehalten</div>
                                {/* Copyright Right */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;